// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/frame-111@3x.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/frame-121@3x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions_strafsenat__zhgCu {
  position: relative;
  font-weight: 600;
}
.Actions_strafsenatWrapper__hecAo {
  flex: 1 1;
  padding: 10px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.Actions_actions__25hUb,
.Actions_livescoreWrapper__EouJw,
.Actions_strafsenatWrapper__hecAo {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.Actions_livescoreWrapper__EouJw {
  flex: 1 1;
  padding: 10px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.Actions_actions__25hUb {
  max-width: 100%;
  height: 263px;
  padding: 10px 0;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}

@media (max-width: 768px) {
  
}
`, "",{"version":3,"sources":["webpack://./src/components/Actions.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,SAAO;EACP,aAAa;EACb,yDAA+C;EAC/C,sBAAsB;EACtB,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;;;EAGE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,aAAa;EACb,yDAA+C;EAC/C,sBAAsB;EACtB,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;;AAEA","sourcesContent":[".strafsenat {\n  position: relative;\n  font-weight: 600;\n}\n.strafsenatWrapper {\n  flex: 1;\n  padding: 10px;\n  background-image: url(/public/frame-111@3x.png);\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n}\n.actions,\n.livescoreWrapper,\n.strafsenatWrapper {\n  overflow: hidden;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  align-self: stretch;\n}\n.livescoreWrapper {\n  flex: 1;\n  padding: 10px;\n  background-image: url(/public/frame-121@3x.png);\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n}\n.actions {\n  max-width: 100%;\n  height: 263px;\n  padding: 10px 0;\n  box-sizing: border-box;\n  gap: 10px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n\n@media (max-width: 768px) {\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strafsenat": `Actions_strafsenat__zhgCu`,
	"strafsenatWrapper": `Actions_strafsenatWrapper__hecAo`,
	"actions": `Actions_actions__25hUb`,
	"livescoreWrapper": `Actions_livescoreWrapper__EouJw`
};
export default ___CSS_LOADER_EXPORT___;
