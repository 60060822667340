// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Newswrapper_newswrapper__9la39 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content within the container */
  justify-content: flex-start;
  gap: 21px;
  max-width: 1200px;
  margin: 0 auto; /* Center the container within the page */
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: "Lexend Deca";
}

.Newswrapper_newsitems__kl-oF {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr)); /* Default for desktop */
  width: 100%; /* Ensure it spans the entire width of the parent */
  justify-content: center; /* Center the grid items */
}

/* Tablet View */
@media (max-width: 1024px) {
  .Newswrapper_newsitems__kl-oF {
    grid-template-columns: repeat(3, minmax(200px, 1fr)); /* 3 columns for tablets */
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .Newswrapper_newsitems__kl-oF {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 columns for mobile */
  }
}

.Newswrapper_newsNeuheitenParent__ePGfc {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 26px;
}

.Newswrapper_allePosts__tkAu6,
.Newswrapper_newsNeuheiten__ptco8 {
  font-weight: 600;
}

.Newswrapper_allePosts__tkAu6 {
  font-size: 25px;
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Newswrapper.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,4CAA4C;EACjE,2BAA2B;EAC3B,SAAS;EACT,iBAAiB;EACjB,cAAc,EAAE,yCAAyC;EACzD,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,oDAAoD,EAAE,wBAAwB;EAC9E,WAAW,EAAE,mDAAmD;EAChE,uBAAuB,EAAE,0BAA0B;AACrD;;AAEA,gBAAgB;AAChB;EACE;IACE,oDAAoD,EAAE,0BAA0B;EAClF;AACF;;AAEA,gBAAgB;AAChB;EACE;IACE,oDAAoD,EAAE,yBAAyB;EACjF;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;AACf","sourcesContent":[".newswrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Center the content within the container */\n  justify-content: flex-start;\n  gap: 21px;\n  max-width: 1200px;\n  margin: 0 auto; /* Center the container within the page */\n  text-align: left;\n  font-size: 16px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n\n.newsitems {\n  display: grid;\n  grid-template-columns: repeat(4, minmax(200px, 1fr)); /* Default for desktop */\n  width: 100%; /* Ensure it spans the entire width of the parent */\n  justify-content: center; /* Center the grid items */\n}\n\n/* Tablet View */\n@media (max-width: 1024px) {\n  .newsitems {\n    grid-template-columns: repeat(3, minmax(200px, 1fr)); /* 3 columns for tablets */\n  }\n}\n\n/* Mobile View */\n@media (max-width: 768px) {\n  .newsitems {\n    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 columns for mobile */\n  }\n}\n\n.newsNeuheitenParent {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0 26px;\n}\n\n.allePosts,\n.newsNeuheiten {\n  font-weight: 600;\n}\n\n.allePosts {\n  font-size: 25px;\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newswrapper": `Newswrapper_newswrapper__9la39`,
	"newsitems": `Newswrapper_newsitems__kl-oF`,
	"newsNeuheitenParent": `Newswrapper_newsNeuheitenParent__ePGfc`,
	"allePosts": `Newswrapper_allePosts__tkAu6`,
	"newsNeuheiten": `Newswrapper_newsNeuheiten__ptco8`
};
export default ___CSS_LOADER_EXPORT___;
