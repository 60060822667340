// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageHeader_image1Icon__jGrXP {
  align-self: stretch;
  flex: 1 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 700px;
  min-height: 500px;
  object-fit: cover;
}
.ImageHeader_winBigPrizes__ox9Qk {
  margin: 0;
}
.ImageHeader_winBigPrizesContainer__mV2ZE {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.ImageHeader_toCelebrateBundesliga__xu5gD {
  align-self: stretch;
  position: relative;
  font-size: 16px;
}
.ImageHeader_winBigPrizesAsPartOfBundParent__4DEHV {
  align-self: stretch;
  justify-content: flex-start;
  gap: 13px;
}
.ImageHeader_imageheader__zgA1r,
.ImageHeader_info__VUtq9,
.ImageHeader_winBigPrizesAsPartOfBundParent__4DEHV {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ImageHeader_info__VUtq9 {
  align-self: stretch;
  background-color: #191e25;
  justify-content: center;
  padding: 30px 18px;
}
.ImageHeader_imageheader__zgA1r {
  width: 100%;
  /*height: 693px;*/
  /*max-height: 1000px;*/
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  max-width: 1200px;
  text-align: left;
  font-size: 29px;
  color: #fff;
  font-family: "Lexend Deca";
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageHeader.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,SAAO;EACP,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;AACA;;;EAGE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;EACd,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".image1Icon {\n  align-self: stretch;\n  flex: 1;\n  position: relative;\n  max-width: 100%;\n  overflow: hidden;\n  max-height: 700px;\n  min-height: 500px;\n  object-fit: cover;\n}\n.winBigPrizes {\n  margin: 0;\n}\n.winBigPrizesContainer {\n  align-self: stretch;\n  position: relative;\n  font-weight: 600;\n}\n.toCelebrateBundesliga {\n  align-self: stretch;\n  position: relative;\n  font-size: 16px;\n}\n.winBigPrizesAsPartOfBundParent {\n  align-self: stretch;\n  justify-content: flex-start;\n  gap: 13px;\n}\n.imageheader,\n.info,\n.winBigPrizesAsPartOfBundParent {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.info {\n  align-self: stretch;\n  background-color: #191e25;\n  justify-content: center;\n  padding: 30px 18px;\n}\n.imageheader {\n  width: 100%;\n  /*height: 693px;*/\n  /*max-height: 1000px;*/\n  overflow: hidden;\n  flex-shrink: 0;\n  justify-content: flex-start;\n  max-width: 1200px;\n  text-align: left;\n  font-size: 29px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image1Icon": `ImageHeader_image1Icon__jGrXP`,
	"winBigPrizes": `ImageHeader_winBigPrizes__ox9Qk`,
	"winBigPrizesContainer": `ImageHeader_winBigPrizesContainer__mV2ZE`,
	"toCelebrateBundesliga": `ImageHeader_toCelebrateBundesliga__xu5gD`,
	"winBigPrizesAsPartOfBundParent": `ImageHeader_winBigPrizesAsPartOfBundParent__4DEHV`,
	"imageheader": `ImageHeader_imageheader__zgA1r`,
	"info": `ImageHeader_info__VUtq9`
};
export default ___CSS_LOADER_EXPORT___;
