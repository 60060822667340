// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scoreitems_div__JlLMW {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scoreitems_scoreitem__QXPc4, .Scoreitems_scoreitem1__nBL5u, .Scoreitems_scoreitem2__sQGxK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scoreitems_scoreitems__y09nY {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; /* Adjust width as needed */
  height: 47px;
  font-size: 27px;
  color: #fff;
  font-family: "Lexend Deca";
}

.Scoreitems_scoreitem__QXPc4 {
  flex: 1 1;
}

.Scoreitems_scoreitem1__nBL5u {
  flex: 0 1; /* Keep the colon centered between the two scores */
}

.Scoreitems_scoreitem2__sQGxK {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Scoreitems.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,EAAE,2BAA2B;EACzC,YAAY;EACZ,eAAe;EACf,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,SAAO,EAAE,mDAAmD;AAC9D;;AAEA;EACE,SAAO;AACT","sourcesContent":[".div {\n  font-weight: 600;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.scoreitem, .scoreitem1, .scoreitem2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.scoreitems {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 120px; /* Adjust width as needed */\n  height: 47px;\n  font-size: 27px;\n  color: #fff;\n  font-family: \"Lexend Deca\";\n}\n\n.scoreitem {\n  flex: 1;\n}\n\n.scoreitem1 {\n  flex: 0; /* Keep the colon centered between the two scores */\n}\n\n.scoreitem2 {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div": `Scoreitems_div__JlLMW`,
	"scoreitem": `Scoreitems_scoreitem__QXPc4`,
	"scoreitem1": `Scoreitems_scoreitem1__nBL5u`,
	"scoreitem2": `Scoreitems_scoreitem2__sQGxK`,
	"scoreitems": `Scoreitems_scoreitems__y09nY`
};
export default ___CSS_LOADER_EXPORT___;
